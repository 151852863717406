<script>
import {mapGetters} from "vuex";
import {debounce} from "@/utils/debounce";
import {Modal as _BSModal} from "bootstrap";

export default {
  data() {
    return {
      selectedAvatar: 0,
      processing: false,
      modal: undefined,
    }
  },
  computed: {
    ...mapGetters([
      'token', 'sessionData'
    ]),
    avatarImage() {
      return require(`@/assets/v2/img/avatar/avatar${this.selectedAvatar < 10 ? '0' : ''}${this.selectedAvatar}.jpg`);
    },
  },
  methods: {
    selectAvatar(avatar) {
      this.selectedAvatar = avatar;
    },
    changeAvatar: debounce(async function () {
      if (this.processing) {
        this.$swal.fire({
          title: '요청을 처리 중입니다. 잠시만 기다려주세요.',
          icon: "warning",
        });
        return false;
      }

      this.processing = true;
      let isSuccess = false;

      try {
        const result = await this.$store.dispatch('user/changeAvatar', {
          token: this.token,
          avatarNumber: this.selectedAvatar,
        });
        isSuccess = result && result.ret === 0;
      }catch (e){
        const msg = this.errorProc(`avatar`, {ret:e});
        console.log(msg);
      } finally {
        if (isSuccess) {
          await this.$store.dispatch('auth_check');
          this.$swal.fire({
            title: '설정한 아바타로 변경되었습니다.',
            icon: "success",
          });
        } else {
          this.$swal.fire({
            title: '요청을 처리하는 중 오류가 발생했습니다.',
            icon: "error",
          });
        }

        this.processing = false;
      }
    }, 500, {leading: true}),
  },
  created() {
    let avatarNumber = (this.sessionData.photo || Math.floor(Math.random() * 20)).toString();
    avatarNumber = avatarNumber.match(/\d/gmi).join('');
    avatarNumber = parseInt(avatarNumber);
    this.selectedAvatar = avatarNumber;
  },
  mounted() {
    this.modal = _BSModal.getOrCreateInstance($(this.$refs.root));
  }
}
</script>

<template>
  <div ref="root" class="modal basic_modal fade" id="player_avatar" tabindex="-1" aria-labelledby="player_avatar" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-lg-down">
      <div class="modal-content wrap_member">
        <div class="modal-header">
          <button type="button" class="btn_back" data-bs-dismiss="modal" aria-label="Close"></button>
          <h1 class="modal-title">아바타</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="my_profile">
            <div class="profile mb-4">
              <div class="centered">
                <img :src="avatarImage" alt="">
              </div>
            </div>
            <div>
              <button type="button" class="btn_d btn_blue" @click.prevent.stop="changeAvatar">저장</button>
            </div>
          </div>
          <div class="area_list">
            <div class="wrap_list">
              <div v-for="num in 40" :key="num" class="sellect_item">
                <div class="thumb" @click.prevent.stop="selectAvatar(num)">
                  <div class="centered">
                    <img :src="require(`@/assets/v2/img/avatar/avatar${num < 10 ? '0' : ''}${num}.jpg`)" alt="">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.content {
  height: calc(100vh - 6.5rem);
}

.sellect_item .thumb {
  cursor: pointer;
}

.sellect_item .thumb:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);

  & img {
    transform: translate(-50%, -50%) scale(1.12);
    transform-origin: center center;
    transition: .3s ease;
  }
}

</style>